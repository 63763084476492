import React from 'react';
import { StyleSheet, Pressable, Text } from 'react-native';

const Button = ({
  title,
  backgroundColor = '#fefefe',
  titleColor = '#000',
  titleSize = 24,
  onPress,
  width = '100%',
  borderRadius= 4,
  borderWidth= 0,
  containerStyle
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={args => {
        if (args.pressed) {
          return [
            styles.base,
            {
              opacity: 0.5,
              backgroundColor: '#333',
              width,
              borderRadius,
              borderWidth
            },
            containerStyle
          ];
        }

        return [
          styles.base,
          {
            opacity: 1,
            backgroundColor: backgroundColor,
            width,
            borderRadius,
            borderWidth
          },
          containerStyle
        ];
      }}
    >
      <Text style={[styles.text, { color: titleColor, fontSize: titleSize }]}>
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'SourceSansPro_300Light',
    alignSelf: 'center',
    textAlign: 'center'
  },
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 54,
    borderColor: '#000',
    paddingHorizontal: 14,
    paddingVertical: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,

    elevation: 6,
  }
});

export default Button;